import helpers from "./helpers";

export default {
    getPublicAccessToken(){
        if (Shopify.shop === 'mj-bale-int-dev.myshopify.com') {
            return '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'
        } else if (Shopify.shop === 'mj-bale-int-uat.myshopify.com') {
            return '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';
        } else if (Shopify.shop === 'mj-bale-international.myshopify.com') {
            return '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';
        }
        return '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';
    },
    getStorefrontToken(){
        if (Shopify.shop === 'mj-bale-int-dev.myshopify.com'){
            return 'eae1e4cf7e6a2d96e3822eef40c1d419'
        } else if (Shopify.shop === 'mj-bale-international.myshopify.com') {
            return 'c3baad2d737e5e24a9d46cdd5cc83dce';
        }
        return '7dddcfd166e8236d7a5d10758cda8140'
    }
}
